<div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="10" tabindex="0">
    <!-- header -->
    <app-header></app-header>
    <!-- end header -->
    <main>
        <!-- whatsapp -->
        <app-whatsapp></app-whatsapp>
        <!-- end wahtsApp -->
        <section class="pb-md-5 position-relative">
            <div class="position-relative faculty-hero d-flex">
                <div class="slid-img position-absolute w-100 h-100 top-0 end-0 overflow-hidden">
                    <img class="position-absolute" src="assets/img/slider/cinema-slide.jpg" />
                    <div class="black-layer position-absolute top-0 end-0 h-100 w-100"></div>
                </div>
                <div
                    class="position-relative d-flex align-items-lg-end justify-content-center institute-pref flex-column inner">
                    <div class="blend-darken-bg position-absolute top-0 end-0 h-100 w-100"></div>
                    <div
                        class="pref position-relative text-white pe-lg-5 pe-3 ms-3 animate__animated animate__slideInRight wow">
                        <h2 class="heading-font d-inline-block pt-5">
                            <label class="d-block text-sm-start">1957</label>
                            المعهد العالي للسينما
                        </h2>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb animate__animated animate__fadeInDown">
                                <li class="breadcrumb-item mb-2 mb-md-0">
                                    <a class="text-white">أكادميه الفنون</a>
                                </li>
                                <li class="breadcrumb-item mb-2 mb-md-0">
                                    <a class="text-white">
                                        المعهد العالي للسينما
                                    </a>
                                </li>
                                <li class="breadcrumb-item mb-2 mb-md-0">
                                    <a class="text-white">
                                        الدراسات الحرة
                                    </a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    تطور فكر فلسفي
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <div class="container-fluid floated-contatiner-top position-relative course-details">
            <div class="row">
                <div class="col-lg-11 offset-lg-1 bg-white">
                    <div class="col-lg-9 offset-lg-1 ps-md-3 pb-5">
                        <section class="pt-4 mt-3 text-md-center">
                            <label
                                class="badge bg-primary-light mb-1 py-1 px-2 lh-base text-black d-sm-none d-inline-block">
                                قسم السيناريو
                            </label>
                            <h1
                                class=" heading-font mb-md-3 mb-0 fw-bold animate__animated animate__fadeInUp animate__faster wow">
                                تطور فكر فلسفي
                            </h1>
                            <div
                                class=" mb-3 d-flex align-items-md-center justify-content-md-center animate__animated animate__fadeInUp animate__fast wow">
                                <span class="fs-5 text-black fw-medium">د/ إسم المحاضر</span>
                                <label
                                    class="badge bg-primary-light ms-3 py-1 px-2 lh-base text-black d-sm-inline-block d-none">
                                    قسم السيناريو
                                </label>
                            </div>
                            <p class="fs-7 fw-medium opacity-75 animate__animated animate__fadeInUp wow mb-4 lh-base">
                                تنقسم دراسة تاريخ الفلسفة تقليدياً إلى ثلاث مراحل: العصور اليونانية القديمة، القرون
                                الوسطى، والعصور
                                الحديثة
                            </p>
                            <div
                                class="d-md-flex align-items-center justify-content-center mb-5 animate__animated animate__fadeInUp wow">
                                <div class="text-black me-3 mb-3 mb-md-0">
                                    <span class="price fw-light">1450</span><span class="fw-medium fs-7">جم</span>
                                </div>
                                <a class="btn btn-primary all py-3 px-4 fs-5 enroll">
                                    سجل قبل 30 سبتمبر
                                    <i class="icon-arrow-sm"></i>
                                </a>
                            </div>
                        </section>
                        <section
                            class="course-img position-relative overflow-hidden animate__animated animate__fadeInUp wow">
                            <img class="fit-img position-absolute" src="assets/img/course-1.jpg">
                            <div class="bg-white p-3 position-absolute bottom-0 start-0 d-md-flex align-items-center">
                                <div class="d-flex me-3 align-items-center">
                                    <i class="icon-calender text-primary me-2"></i>
                                    <div class="date d-flex flex-column ">
                                        <label class="fw-medium">تاريخ البدء</label>
                                        <p class="fw-medium mb-0">19 سبتمبر 2022</p>
                                    </div>
                                </div>
                                <div class="d-flex px-md-3 border-start align-items-center course-time mt-3 mt-md-0">
                                    <i class="icon-time text-primary me-2"></i>
                                    <div class="date d-flex flex-column ">
                                        <label class="fw-medium">موعد الدورة</label>
                                        <p class="fw-medium mb-0">كل أربع الساعه 6 مساء</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="pt-5 animate__animated animate__fadeIn animate__faster">
                            <h5 class="text-black fw-bold mb-3 ">
                                ماذا ستتعلم
                            </h5>
                            <ul class="list-unstyled mb-0 custom-styled ps-4 fw-medium text-muted">
                                <li>فصلوا الفلسفة عن الآداب والأساطير والدين ومعتقدات الأولين</li>
                                <li>بحثوا كل مشكلة من المشكلات الفلسفية بحثاً مستقلاً</li>
                                <li>تمحيص الدين وتصفيته وإصلاحه ومن أفضل الأمثلة على ذلك حركة الإصلاح الديني التي قام
                                    بها "إخناتون".
                                </li>
                                <li>فصلوا الفلسفة عن الآداب والأساطير والدين ومعتقدات الأولين</li>
                            </ul>
                        </section>
                        <section class="pt-5 animate__animated animate__fadeIn animate__fast">
                            <h5 class="text-black fw-bold mb-0 lh-base">
                                محتوى الدورة
                            </h5>
                            <p class="text-muted fw-medium">3 أجزاء - 12 ساعة - 6 محاضرات</p>
                            <div class="accordion" id="accordionCourseContent">
                                <div class="accordion-item border-0 border-bottom">
                                    <h2 class="accordion-header" id="heading1">
                                        <button class="accordion-button fw-medium collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true"
                                            aria-controls="collapse1">
                                            <div class="flex-fill fs-5">
                                                مقدمة
                                            </div>
                                            <div
                                                class="accordion-header-label opacity-100 me-3 text-gray d-none d-sm-inline-block">
                                                2 محاضرة
                                            </div>
                                        </button>
                                    </h2>
                                    <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="heading1"
                                        data-bs-parent="#accordionCourseContent">
                                        <div class="accordion-body px-0">
                                            <ul class="list-unstyled mb-0 custom-styled ps-4 fw-medium">
                                                <li>
                                                    عنوان المحاضره
                                                    <p class="blockquote-footer my-0">
                                                        شرح عن المحاضر
                                                    </p>
                                                </li>
                                                <li>
                                                    عنوان المحاضره
                                                    <p class="blockquote-footer my-0">
                                                        شرح عن المحاضر
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item border-0 border-bottom">
                                    <h2 class="accordion-header" id="heading2">
                                        <button class="accordion-button fw-medium collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false"
                                            aria-controls="collapse2">
                                            <div class="flex-fill fs-5 ">
                                                فصل الفلسفة عن الآداب والأساطير
                                            </div>
                                            <div
                                                class="accordion-header-label opacity-100 me-3 text-gray d-none d-sm-inline-block">
                                                2 محاضرة
                                            </div>
                                        </button>
                                    </h2>

                                    <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2"
                                        data-bs-parent="#accordionCourseContent">
                                        <div class="accordion-body px-0">
                                            <ul class="list-unstyled mb-0 custom-styled ps-4 fw-medium">
                                                <li>
                                                    عنوان المحاضره
                                                    <p class="blockquote-footer my-0">
                                                        شرح عن المحاضر
                                                    </p>
                                                </li>
                                                <li>
                                                    عنوان المحاضره
                                                    <p class="blockquote-footer my-0">
                                                        شرح عن المحاضر
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                        <section class="pt-5 animate__animated animate__fadeIn">
                            <h5 class="text-black fw-bold mb-3 lh-base">
                                متطلبات
                            </h5>
                            <p class="text-muted fw-medium">جميع المستويات</p>
                            <ul class="list-unstyled mb-0 custom-styled ps-4 fw-medium text-muted">
                                <li>
                                    حد أدني من الثقافه العامه
                                </li>
                            </ul>
                        </section>
                        <section class="pt-5 fw-medium animate__animated animate__fadeIn">
                            <h5 class="text-black fw-bold lh-base">
                                وصف الدورة
                            </h5>
                            <p class="text-muted">
                                هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع
                                ودور النشر. كان
                                لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة
                                برص مجموعة من
                                الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة
                                قرون من الزمن
                                لم تقضي على هذا النص، بل انه حتى صار
                            </p>
                            <h6 class="text-black mt-4">
                                ماذا ستتعلم بعد أنتهاء الدورة؟
                            </h6>
                            <p class="text-muted">
                                هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع
                                ودور النشر. كان
                                لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة
                                برص مجموعة من
                                الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة
                                قرون من الزمن
                                لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد
                                الإلكتروني. انتشر بشكل
                                كبير في ستينيّات هذا القرن مع إصدار رقائق
                            </p>
                            <h6 class="text-black mt-4">مخطط هذه الدورة</h6>
                            <ol class="text-muted ps-3 lh-base">
                                <li class="pb-2">
                                    هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم
                                </li>
                                <li class="pb-2">
                                    كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس
                                </li>
                                <li class="pb-2">
                                    عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي
                                </li>
                                <li class="pb-2">
                                    أخذتها من نص، لتكوّن كتيّب بمثابة دليل
                                </li>
                            </ol>
                            <h6 class="text-black mt-4">لمن هذه الدورة</h6>
                            <ul class="list-unstyled mb-0 custom-styled ps-4 fw-medium text-muted">
                                <li>مخرج سينمائي</li>
                                <li>كاتب سيناريو</li>

                            </ul>
                        </section>
                        <section class="pt-5 fw-medium animate__animated animate__fadeIn">
                            <h5 class="text-black fw-bold mb-3 lh-base">
                                محاضر الدوره
                            </h5>
                            <div class="d-flex">
                                <div class="flex-md-shrink-0 instructor-img">
                                    <img src="assets/img/instructor-default.svg">
                                </div>
                                <div class="flex-grow-1 ms-md-3 ms-2">
                                    <h5 class="mb-0 text-primary">د/ إسم المحاضر</h5>
                                    <p class="text-black mb-2">المسمى الوظيفي</p>
                                    <p class="text-gray">
                                        هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات
                                        المطابع ودور النشر. كان
                                        المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة
                                    </p>
                                </div>
                            </div>
                        </section>

                    </div>
                    <section class="pt-5 animate__animated animate__fadeInDown animate__faster offset-lg-1">
                        <h4 class="mb-3">
                            الدورات المتاحه
                        </h4>
                        <!-- Swiper -->
                        <div class="swiper courses-list-card hListSwiper pb-5">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <a class="card card-hover border-0 text-black">
                                        <div class="card-img-top position-relative overflow-hidden">
                                            <img src="assets/img/course-1.jpg" class="fit-img position-absolute">
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title fw-bold">تطور فكر فلسفي</h5>
                                            <p class="card-text">د/ إسم المحاضر</p>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="card card-hover border-0 text-black">
                                        <div class="card-img-top position-relative overflow-hidden">
                                            <img src="assets/img/course-2.jpg" class="fit-img position-absolute">
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title fw-bold">تاريخ وتذوق فنون</h5>
                                            <p class="card-text">د/ إسم المحاضر</p>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <a class="card card-hover border-0 text-black">
                                        <div class="card-img-top position-relative overflow-hidden">
                                            <img src="assets/img/course-3.jpg" class="fit-img position-absolute">
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title fw-bold">فنية تطور تاريخ</h5>
                                            <p class="card-text">د/ إسم المحاضر</p>
                                        </div>
                                    </a>
                                </div>
                                <div class="swiper-slide">
                                    <div class="card card-hover border-0">
                                        <div class="card-img-top position-relative overflow-hidden">
                                            <img src="assets/img/course-4.jpg" class="fit-img position-absolute">
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title fw-bold">تطور فكر فلسفي</h5>
                                            <p class="card-text">د/ إسم المحاضر</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <a class="card card-hover border-0 text-black">
                                        <div class="card-img-top position-relative overflow-hidden">
                                            <img src="assets/img/course-1.jpg" class="fit-img position-absolute">
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title fw-bold">تطور فكر فلسفي</h5>
                                            <p class="card-text">د/ إسم المحاضر</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <section class="container-fluid pt-lg-5 pt-3 pb-lg-5 pb-3">
            <div class="row justify-content-center">
                <div
                    class="col-xl-3 col-md-6 mb-md-0 mb-3 pb-md-0 pb-3 animate__animated animate__fadeInUp animate__fast wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            الأسئلة الشائعة
                            <p class="mb-0 text-gray mt-1">إستفسارات شائعة حول الدراسات الحره</p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
                <div
                    class="col-xl-3 col-md-6 mb-md-0 mb-3 pb-md-0 pb-3 animate__animated animate__fadeInUp animate__faster wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            موقع الطالب
                            <p class="mb-0 text-gray mt-1">
                                تعرف على المواد و المهام و النتيجة
                            </p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
            </div>
        </section>
    </main>
    <app-footer></app-footer>
</div>