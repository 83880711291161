import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-register-confirm',
  templateUrl: './course-register-confirm.component.html',
  styleUrls: ['./course-register-confirm.component.css']
})
export class CourseRegisterConfirmComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
