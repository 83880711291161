<div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="10" tabindex="0">
    <app-header></app-header>
    <main>

     <!-- whatsapp floating button -->
     <app-whatsapp></app-whatsapp>
     <!-- END whatsapp floating button -->

        <!--HERO Faculty-->
        <section class="pb-md-5 position-relative">
            <div class="position-relative faculty-hero d-flex">
                <div class="slid-img position-absolute w-100 h-100 top-0 end-0 overflow-hidden">
                    <img class="position-absolute" src="{{faculty_image}}" />
                    <div class="black-layer position-absolute top-0 end-0 h-100 w-100"></div>
                </div>
                <div
                    class="position-relative d-flex align-items-lg-end justify-content-center institute-pref flex-column inner">
                    <div class="blend-darken-bg position-absolute top-0 end-0 h-100 w-100"></div>
                    <div
                        class="pref position-relative text-white pe-lg-5 pe-3 ms-3 animate__animated animate__slideInRight wow">
                        <h2 class="heading-font d-inline-block pt-5">
                            <label class="d-block text-sm-start">1957</label>
                            {{faculty_name}}
                        </h2>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb animate__animated animate__fadeInDown">
                                <li class="breadcrumb-item mb-2 mb-md-0">
                                    <a class="text-white">أكادميه الفنون</a>
                                </li>
                                <li class="breadcrumb-item mb-2 mb-md-0">
                                    <a class="text-white">
                                        {{faculty_name}}
                                    </a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                     الانظمة التعليمية للبكالوريوس
                                </li> 
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        <div class="container-fluid floated-contatiner-top position-relative pb-lg-5">
            <div class="row">
                <!--Desktop side nav-->
                <div class="col-lg-3 pt-5 mt-4 pe-0 d-lg-block d-none">
                    <div class="bg-secondary p-3 h-100 list-group" id="navbar-example2">
                        <h4 class="text-white border-bottom border-primary pb-2 mt-2 mb-4 d-inline-block">
                            <span class="opacity-50">
                                الانظمة التعليمية للبكالوريوس
                            </span>
                        </h4>
                        <ul class="list-unstyled side-nav pt-3 sticky-top" role="tablist">
                            <li class="mb-4 animate__animated animate__fadeInRight animate__faster wow">
                                <a class="d-block p-3 text-white fs-5 list-group-item list-group-item-action"
                                (click)="scroolDowen('element1')" >
                                    التعليم النظامى
                                </a>
                            </li>
                            <li *ngIf="has_credit" class="mb-4 animate__animated animate__fadeInRight animate__fast wow">
                                <a class="d-block p-3 text-white fs-5 list-group-item list-group-item-action"
                                (click)="scroolDowen('elemnt')">
                                    التعليم الموازي بمصروفات
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!--END Desktop side nav-->
                <div class="col-lg-9 bg-white pt-5 pb-lg-5">
                    <section class="px-2">
                        <h3
                            class="text-center heading-font heading-style mb-4 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                            الانظمة التعليمية للبكالوريوس
                        </h3>
                        <p class="fs-6 fw-medium animate__animated animate__fadeInUp animate__fast wow">
                            يتم تطبيق برنامج الساعات المعتمدة لمعاهد اكاديمية الفنون مرحلة البكالوريوس بنظامين هما
                            التعليم النظامى و
                            التعليم الموازي بمصروفات تعرف علي الفرق ما بينهم فيما يلي
                        </p>
                    </section>
                    <!--Mobile side nav-->
                    <section class="sticky-top bg-light side-nav py-1 d-block d-lg-none row">
                        <div class="col">
                            <div class="swiper tablistSwiper" role="tablist">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <a class="d-block p-2 border list-group-item list-group-item-action fw-bold"
                                            href="#supported">
                                            التعليم النظامى
                                        </a>
                                    </div>
                                    <div class="swiper-slide">
                                        <a class="d-block p-2 border list-group-item list-group-item-action fw-bold"
                                            href="#notSupported">
                                            التعليم الموازي بمصروفات
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <!--End Mobile side nav-->

                    <section class="py-5 px-2" id="element1">
                        <div class="goto-link position-relative" id="supported"></div>
                        <h1 #element1 class="heading-font mb-4 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                            التعليم النظامى
                        </h1>
                        <p class="fs-6 fw-medium animate__animated animate__fadeInUp animate__fast wow mb-4">
                                النظام الموازي بمصروفات نظام تعليمي بمصروفات صباحي يمنح نفس الشهادة التي يمنحها النظام العادي، ويسمح أيضا بالحصول على عضويات النقابات، الفارق الوحيد هنا يتمثل في المصروفات التي يدفعها الطالب وفي مرونة الشروط الخاصة بالقبول وفي الاعداد المسموح بقبولها كل عام، وتبلغ مدة الدراسة فيه 4 سنوات أو 8 فصول دراسية كحد أدنى.
                        </p>
                        <div class="animate__animated animate__fadeInUp wow mb-5">
                            <h5 class="text-black fw-bold">مصروفات الدراسه في أقسام المعهد</h5>
                            <p class="text-gray fw-medium">
                                هذه الرسوم السنوية مخصصة لطلاب البكالوريوس بدوام كامل
                                
                            </p>
                            <div class="table-responsive mb-4">
                                <table class="table table-bordered fw-medium text-nowrap mb-0">
                                    <thead class="gray-head">
                                        <tr>
                                            <th scope="col">الطلبة المصريين</th>
                                            <th scope="col">الطلبة غير المصريين</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span class="fs-4">{{Mo3tamd_egyptian_egp_fees}}</span>جم - سنويا
                                            </td>
                                            <td>
                                                <span class="fs-4">{{Mo3tamd_forgnier_usd_fees}}</span> دولار + <span class="fs-4">{{Mo3tamd_forgnier_egp_fees ? Mo3tamd_forgnier_egp_fees : 0}}</span> جم
                                                - سنويا
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <!--JOIN ROW-->
                        <!-- <div class="join-now mb-5 row animate__animated animate__fadeInUp wow">
                            <div class="col-xl-6 mb-4 mb-xl-0">
                                <a
                                    class="btn btn-primary text-white d-flex align-items-center text-start py-3 px-4 fs-6 h-100">
                                    <div class="flex-fill pe-3">
                                        انضم إلى العام الدراسي الجديد في سبتمبر
                                        <span class="d-block fw-bold pt-1"> سجل الان </span>
                                    </div>
                                    <i class="icon-arrow-sm default d-block overflow-hidden"></i>
                                </a>
                            </div>
                            <div class="col-xl-6">
                                <a
                                    class="btn btn-link border border-primary d-flex align-items-center text-start py-3 px-4 fs-6">
                                    <div class="flex-fill pe-3">
                                        <div class="text-black fw-medium">
                                            شروط القبول والتسجيل للبرامج الدراسيه المتاحه
                                        </div>
                                        <span class="d-block fw-bold pt-1">إقراء المزيد</span>
                                    </div>
                                    <i class="icon-arrow-sm default d-block overflow-hidden"></i>
                                </a>
                            </div>
                        </div> -->
                        <!--END JOIN ROW-->
                    </section>
                    <section class="py-5 px-2" id="elemnt"  *ngIf="has_credit">
                        <div class="goto-link position-relative" id="notSupported"></div>
                        <h1 class="heading-font mb-4 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                            التعليم الموازي بمصروفات
                        </h1>
                        <p class="fs-6 fw-medium animate__animated animate__fadeInUp animate__fast wow mb-4">
                            النظام الموازي بمصروفات نظام تعليمي بمصروفات صباحي يمنح نفس الشهادة التي يمنحها النظام
                            العادي، ويسمح أيضا بالحصول على عضوية نقابة المهن السينمائية، الفارق الوحيد هنا يتمثل في
                            المصروفات التي يدفعها الطالب وفي مرونة الشروط الخاصة بالقبول وفي الاعداد المسموح بقبولها كل
                            عام، وتبلغ مدة الدراسة فيه 4 سنوات أو 8 فصول دراسية كحد أدنى.
                        </p>
                        <div class="animate__animated animate__fadeInUp wow mb-5">
                            <h5 class="text-black fw-bold">مصروفات الدراسه في أقسام المعهد</h5>
                            <p class="text-gray fw-medium">
                                هذه الرسوم السنوية مخصصة لطلاب البكالوريوس بدوام كامل 
                               
                            </p>
                            <div class="table-responsive mb-4">
                                <table class="table table-bordered fw-medium text-nowrap mb-0">
                                    <thead class="gray-head">
                                        <tr>
                                            <th scope="col">الطلبة المصريين</th>
                                            <th scope="col">الطلبة غير المصريين</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span class="fs-4">{{credit_egyptian_egp_fees}}</span>جم - سنويا
                                            </td>
                                            <td>
                                                <span class="fs-4">{{credit_forgnier_egp_fees}}</span> دولار + <span class="fs-4">{{credit_forgnier_usd_fees ? credit_forgnier_usd_fees : 0}}</span> جم
                                                - سنويا
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="accordion" id="accordionDepartment">
                                <div class="accordion-item border-0 border-bottom" *ngFor="let item of faculty_depart;let i = index">
                                    <h2 class="accordion-header" id="heading1{{i}}">
                                        <button class="accordion-button fw-medium fs-5" type="button"
                                            data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse1'+i" aria-expanded="true"
                                            [attr.aria-controls]="'collapse1'+i">
                                            <div class="flex-fill">
                                                {{item.department_name}}
                                            </div>
                                            <!-- <div class="accordion-header-label opacity-100 me-3">
                                                90 <span class="fs-label text-gray">جم - للساعه</span>
                                            </div> -->
                                        </button>
                                    </h2>
                                    <div id="collapse1{{i}}" class="accordion-collapse collapse"
                                    [ngClass]="{'show': i == 0}"
                                        [attr.aria-labelledby]="'heading1'+i" data-bs-parent="#accordionDepartment">
                                        <div class="accordion-body px-0">
                                            <div class="table-responsive mb-4">
                                                <table class="table table-bordered fw-medium text-nowrap mb-0">
                                                    <thead class="gray-head">
                                                        <tr>
                                                            <th scope="col">المتطلبات</th>
                                                            <th scope="col">السعر للطلبة المصريين</th>
                                                            <th scope="col">السعر للطلبة الغير المصريين بالجنية المصري</th>
                                                            <th scope="col">السعر للطلبة الغير المصريين  بالدولار</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let depart of item.egyptian_egp_dept_coursetype_price ; let index = index">
                                                            <td><span class="fs-4">{{depart.course_type_name}}</span> </td>
                                                            <td><span class="fs-4">{{depart.course_type_price}}</span> </td>
                                                            <td><span class="fs-4">{{item.forgnier_egp_dept_coursetype_price[index].course_type_price}}</span> </td>
                                                            <td><span class="fs-4">{{item.forgnier_usd_dept_coursetype_price[index].course_type_price}}</span> </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="accordion-item border-0 border-bottom">
                                    <h2 class="accordion-header" id="heading2">
                                        <button class="accordion-button fw-medium fs-5 collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false"
                                            aria-controls="collapse2">
                                            <div class="flex-fill">
                                                قسم الاخراج
                                            </div>
                                            <div class="accordion-header-label opacity-100 me-3">
                                                90 <span class="fs-label text-gray">جم - للساعه</span>
                                            </div>
                                        </button>
                                    </h2>

                                    <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2"
                                        data-bs-parent="#accordionDepartment">
                                        <div class="accordion-body px-0">
                                            <div class="table-responsive mb-4">
                                                <table class="table table-bordered fw-medium text-nowrap mb-0">
                                                    <thead class="gray-head">
                                                        <tr>
                                                            <th scope="col">سعر الساعه</th>
                                                            <th scope="col">المجموع لشهاده البكالوريوس</th>
                                                            <th scope="col">الحد الأقصى في الفصل </th>
                                                            <th scope="col">الحد الأدني في الفصل </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><span class="fs-4">1000</span> جم</td>
                                                            <td><span class="fs-4">155</span> ساعه</td>
                                                            <td><span class="fs-4">100</span> ساعه</td>
                                                            <td><span class="fs-4">50</span> ساعه</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                            </div>

                        </div>
                        <!--JOIN ROW-->
                        <!-- <div class="join-now row animate__animated animate__fadeInUp wow">
                            <div class="col-xl-6 mb-4 mb-xl-0">
                                <a
                                    class="btn btn-primary text-white d-flex align-items-center text-start py-3 px-4 fs-6 h-100">
                                    <div class="flex-fill pe-3">
                                        انضم إلى العام الدراسي الجديد في سبتمبر
                                        <span class="d-block fw-bold pt-1"> سجل الان </span>
                                    </div>
                                    <i class="icon-arrow-sm default d-block overflow-hidden"></i>
                                </a>
                            </div>
                            <div class="col-xl-6">
                                <a
                                    class="btn btn-link border border-primary d-flex align-items-center text-start py-3 px-4 fs-6">
                                    <div class="flex-fill pe-3">
                                        <div class="text-black fw-medium">
                                            شروط القبول والتسجيل للبرامج الدراسيه المتاحه
                                        </div>
                                        <span class="d-block fw-bold pt-1">إقراء المزيد</span>
                                    </div>
                                    <i class="icon-arrow-sm default d-block overflow-hidden"></i>
                                </a>
                            </div>
                        </div> -->
                        <!--END JOIN ROW-->

                    </section>
                </div>
            </div>
        </div>

        <!-- <section class="container-fluid pt-lg-5 pt-3 pb-lg-5 pb-3">
            <div class="row">
                <div
                    class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__faster wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            موقع الطالب
                            <p class="mb-0 text-gray mt-1">
                                تعرف على المواد و المهام و النتيجة
                            </p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
                <div
                    class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__fast wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            موقع هيئة التدريس
                            <p class="mb-0 text-gray mt-1">تابع متطلبات الطلبه</p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
                <div
                    class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__fast wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            موقع الموظفين
                            <p class="mb-0 text-gray mt-1">تابع متطلبات الطلبه</p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
                <div class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            خدمات الخريجين
                            <p class="mb-0 text-gray mt-1">
                                تعرف على الخدمات الخاصه بالخرجين
                            </p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
            </div>
        </section> -->

    </main>
</div>