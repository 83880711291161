<div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="10" tabindex="0">
    <app-header></app-header>
    <main>

        <!-- whatsapp floating button -->
        <app-whatsapp></app-whatsapp>
        <!-- END whatsapp floating button -->

        <!--HERO Faculty-->
        <section class="pb-md-5 position-relative">
            <div class="position-relative faculty-hero d-flex">
                <div class="slid-img position-absolute w-100 h-100 top-0 end-0 overflow-hidden">
                    <img class="position-absolute" src="assets/img/slider/cinema-slide.jpg" />
                    <div class="black-layer position-absolute top-0 end-0 h-100 w-100"></div>
                </div>
                <div
                    class="position-relative d-flex align-items-lg-end justify-content-center institute-pref flex-column inner">
                    <div class="blend-darken-bg position-absolute top-0 end-0 h-100 w-100"></div>
                    <div
                        class="pref position-relative text-white pe-lg-5 pe-3 ms-3 animate__animated animate__slideInRight wow">
                        <h2 class="heading-font d-inline-block pt-5">
                            <label class="d-block text-sm-start">1957</label>
                            المعهد العالي للسينما
                        </h2>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb animate__animated animate__fadeInDown">
                                <li class="breadcrumb-item mb-2 mb-md-0">
                                    <a class="text-white">أكادميه الفنون</a>
                                </li>
                                <li class="breadcrumb-item mb-2 mb-md-0">
                                    <a class="text-white">
                                        المعهد العالي للسينما
                                    </a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    دراسة البكالوريوس
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        <div class="container-fluid floated-contatiner-top position-relative">
            <div class="row">
                <div class="col-lg-11 offset-lg-1 bg-white">
                    <div class="col-lg-11 ps-3">
                        <section class="pt-4 mt-3">
                            <h3
                                class="text-center heading-font heading-style mb-4 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                                دراسة البكالوريوس
                            </h3>
                            <p class="fs-5 fw-medium animate__animated animate__fadeInUp animate__fast wow">
                                الدراسة مع التركيز على الممارسة, لا يمنحك الحصول على درجة البكالوريوس من المعهد العالي
                                للسينما فقط أسسًا أكاديمية قوية. هناك مشاريع عملية ستعلمك عن العمل اليومي في مجال عملك ،
                                بحيث يمكنك الانطلاق إلى بداية رائعة بعد التخرج.
                            </p>
                        </section>
                        <section class="py-5 px-2">
                            <h2
                                class="heading-font mb-4 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                                تخصصات البكالوريوس التي يقدمها المعهد
                            </h2>
                            <div class="row  ">
                                <div
                                    class="col-lg-4 col-md-6 mb-4 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__faster">
                                    <a
                                        class="btn btn-link caption border border-primary d-flex align-items-center text-start p-3 fs-6 h-100">
                                        <div class="fw-medium flex-fill p-1">
                                            السيناريو
                                            <p class="mb-0 text-gray mt-1">
                                                فن حرفة الكتابة لقطاع الإعلام
                                            </p>
                                        </div>
                                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                                    </a>
                                </div>
                                <div
                                    class="col-lg-4 col-md-6 mb-4 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__faster">
                                    <a
                                        class="btn btn-link caption border border-primary d-flex align-items-center text-start p-3 fs-6 h-100">
                                        <div class="fw-medium flex-fill p-1">
                                            الاخراج
                                            <p class="mb-0 text-gray mt-1">
                                                إدارة العمل الفني أياً كان نوعه
                                            </p>
                                        </div>
                                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                                    </a>
                                </div>
                                <div
                                    class="col-lg-4 col-md-6 mb-4 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__faster">
                                    <a
                                        class="btn btn-link caption border border-primary d-flex align-items-center text-start p-3 fs-6 h-100">
                                        <div class="fw-medium flex-fill p-1">
                                            المونتاج
                                            <p class="mb-0 text-gray mt-1">
                                                اختيار وترتيب المشاهد وطولها الزمني
                                            </p>
                                        </div>
                                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                                    </a>
                                </div>
                                <div
                                    class="col-lg-4 col-md-6 mb-4 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__fast">
                                    <a
                                        class="btn btn-link caption border border-primary d-flex align-items-center text-start p-3 fs-6 h-100">
                                        <div class="fw-medium flex-fill p-1">
                                            الانتاج
                                            <p class="mb-0 text-gray mt-1">
                                                حصيلة جميع الأفكار والقرارات والتجهيزات
                                            </p>
                                        </div>
                                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                                    </a>
                                </div>
                                <div
                                    class="col-lg-4 col-md-6 mb-4 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__fast">
                                    <a
                                        class="btn btn-link caption border border-primary d-flex align-items-center text-start p-3 fs-6 h-100">
                                        <div class="fw-medium flex-fill p-1">
                                            هندسة الصوت
                                            <p class="mb-0 text-gray mt-1">
                                                وقواعد ونظريات الصوت
                                            </p>
                                        </div>
                                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                                    </a>
                                </div>
                                <div
                                    class="col-lg-4 col-md-6 mb-4 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__fast">
                                    <a
                                        class="btn btn-link caption border border-primary d-flex align-items-center text-start p-3 fs-6 h-100">
                                        <div class="fw-medium flex-fill p-1">
                                            هندسة المناظر
                                            <p class="mb-0 text-gray mt-1">
                                                تصميم وتزيين الديكور الداخلي والخارجي
                                            </p>
                                        </div>
                                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                                    </a>
                                </div>
                                <div class="col-lg-4 col-md-6 mb-4 pb-lg-0 pb-3 animate__animated animate__fadeInUp">
                                    <a
                                        class="btn btn-link caption border border-primary d-flex align-items-center text-start p-3 fs-6 h-100">
                                        <div class="fw-medium flex-fill p-1">
                                            الرسوم المتحركة
                                            <p class="mb-0 text-gray mt-1">
                                                لتتابع من الصور ثنائية البعد أو ثلاثية الأبعاد
                                            </p>
                                        </div>
                                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                                    </a>
                                </div>
                                <div class="col-lg-4 col-md-6 mb-4 pb-lg-0 pb-3 animate__animated animate__fadeInUp">
                                    <a
                                        class="btn btn-link caption border border-primary d-flex align-items-center text-start p-3 fs-6 h-100">
                                        <div class="fw-medium flex-fill p-1">
                                            التصوير
                                            <p class="mb-0 text-gray mt-1">
                                                صناعة التصوير المتحرك وعرضه للجمهور
                                            </p>
                                        </div>
                                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                                    </a>
                                </div>
                            </div>
                        </section>
                        <section class="py-5 px-2">
                            <h2
                                class="heading-font mb-4 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                                كل ما يجب أن تعرفه عن الدراسة للحصول على درجة البكالوريوس
                            </h2>
                            <!--brife grid-->
                            <div class="pb-5 row">
                                <div class="col-lg-6 pb-4 animate__animated animate__fadeInUp animate__faster wow">
                                    <div
                                        class="brief bg-secondary position-relative p-4 overflow-hidden text-white h-100">
                                        <img class="position-absolute fit-img" src="assets/img/register-conditions.jpg">
                                        <div
                                            class="position-absolute w-100 h-100 bg-secondary opacity-75 top-0 start-0">
                                        </div>
                                        <div class="position-relative">
                                            <h3 class="heading-font">
                                                القبول والتسجيل
                                            </h3>
                                            <p class="mb-0">
                                                قدم عبر الإنترنت و تعرف على شروط و تفاصيل التقديم و مواعيد الاختبارات و
                                                شروطها و التقديم لها
                                            </p>
                                            <a class="btn text-white align-items-center all d-inline-flex ps-1 mt-2">
                                                <span class="fw-bold fs-6">
                                                    إقراء المزيد
                                                </span>
                                                <i class="icon-arrow-sm"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 pb-4 animate__animated animate__fadeInUp animate__fast wow">
                                    <div class="brief bg-primary p-4 text-white h-100">
                                        <div class="position-relative">
                                            <h3 class="heading-font">
                                                التكلفة والمصروفات
                                            </h3>
                                            <p class="mb-0">
                                                يتم تطبيق برنامج الساعات المعتمدة لمعاهد اكاديمية الفنون مرحلة
                                                البكالوريوس بنظامين هما التعليم النظامى و التعليم الموازي بمصروفات
                                            </p>
                                            <a
                                                class="btn text-white btn-primary align-items-center all d-inline-flex ps-1 mt-2">
                                                <span class="fw-bold fs-6">
                                                    تعرف علي الفرق ما بينهم
                                                </span>
                                                <i class="icon-arrow-sm"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 pb-4 animate__animated animate__fadeInUp animate__fast wow">
                                    <div class="brief border p-4 h-100">
                                        <div class="position-relative">
                                            <h3 class="heading-font">
                                                المواد و الأدوات الازمه
                                            </h3>
                                            <p class="mb-0">
                                                تعرف على المواد التي يتم دراستها في كل قسم و الأدوات المطلوبه
                                            </p>
                                            <div class="row mt-3">
                                                <div class="col px-0 border bg-light">
                                                    <div class="floating-label-select position-relative">
                                                        <select
                                                            class="floating-select form-select border-0 fw-medium bg-light"
                                                            onclick="this.setAttribute('value', this.value);" value="">
                                                            <option value=""></option>
                                                            <option value="1">السيناريو</option>
                                                            <option value="2"> المونتاج</option>
                                                        </select>
                                                        <label>إختر القسم</label>
                                                    </div>
                                                </div>
                                                <div class="col-auto px-0 border border-start-0">
                                                    <button
                                                        class="btn btn-link search-btn position-relative h-100 d-flex align-items-center justify-content-center">
                                                        <i class="icon-search position-relative"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 pb-4 animate__animated animate__fadeInUp animate__fast wow">
                                    <div
                                        class="brief bg-secondary position-relative p-4 overflow-hidden text-white h-100">
                                        <img class="position-absolute fit-img" src="assets/img/register.jpg">
                                        <div
                                            class="position-absolute w-100 h-100 bg-secondary opacity-75 top-0 start-0">
                                        </div>
                                        <div class="position-relative">
                                            <h3 class="heading-font">
                                                سجل الان
                                            </h3>
                                            <p class="mb-0">
                                                انضم إلى العام الدراسي الجديد في سبتمبر متبقي 27 يوم فقط للتسجيل !
                                            </p>
                                            <a class="btn text-white align-items-center all d-inline-flex ps-1 mt-2">
                                                <span class="fw-bold fs-6">
                                                    قدم طلب
                                                </span>
                                                <i class="icon-arrow-sm"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!--END brife grid-->
                        </section>
                        <section class="py-5 px-2">
                            <h2
                                class="heading-font mb-1 fw-normal text-center animate__animated animate__fadeInUp animate__faster wow">
                                البكالوريوس ليس المناسب لك؟
                            </h2>
                            <p
                                class="mb-4 fs-5 opacity-75 text-center fw-medium animate__animated animate__fadeInUp animate__fast wow">
                                برنامج البكالوريوس ليس مناسب لمهاراتك يمكنك اللإطلاع على البرامج الأخرى
                            </p>
                            <div class="row">
                                <div class="col-lg-10 mx-auto">
                                    <div class="row">
                                        <div
                                            class="col-md-6 mb-md-0 mb-5 animate__animated animate__fadeInUp animate__fast wow">
                                            <a
                                                class="position-relative hover-img overflow-hidden programs-link d-block">
                                                <img src="assets/img/master.jpg">
                                                <div
                                                    class="position-absolute top-0 start-0 p-4 bg-white fs-4 text-black fw-medium d-flex align-items-center">
                                                    اﻟﺪراﺳﺎت اﻟﻌﻠﻴﺎ
                                                    <i class="icon-arrow-sm text-primary ms-1"></i>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-6 animate__animated animate__fadeInUp wow">
                                            <a
                                                class="position-relative hover-img overflow-hidden programs-link d-block">
                                                <img src="assets/img/freelance.jpg">
                                                <div
                                                    class="position-absolute bottom-0 end-0 p-4 bg-white fs-4 text-black fw-medium d-flex align-items-center">
                                                    اﻟﺪراﺳﺎت اﻟﺤﺮة
                                                    <i class="icon-arrow-sm text-primary ms-1"></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <section class="container-fluid pt-lg-5 pt-3 pb-lg-5 pb-3">
            <div class="row">
                <div
                    class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__faster wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            موقع الطالب
                            <p class="mb-0 text-gray mt-1">
                                تعرف على المواد و المهام و النتيجة
                            </p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
                <div
                    class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__fast wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            موقع هيئة التدريس
                            <p class="mb-0 text-gray mt-1">تابع متطلبات الطلبه</p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
                <div
                    class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__fast wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            موقع الموظفين
                            <p class="mb-0 text-gray mt-1">تابع متطلبات الطلبه</p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
                <div class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            خدمات الخريجين
                            <p class="mb-0 text-gray mt-1">
                                تعرف على الخدمات الخاصه بالخرجين
                            </p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
            </div>
        </section>

    </main>
</div>