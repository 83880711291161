<div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="10" tabindex="0">
    <app-header></app-header>
    <main>
        <!-- whatsapp floating button -->
        <app-whatsapp></app-whatsapp>
        <!-- END whatsapp floating button -->

        <!--HERO Faculty-->
        <section class="pb-md-5 position-relative">
            <div class="position-relative faculty-hero d-flex">
                <div class="slid-img position-absolute w-100 h-100 top-0 end-0 overflow-hidden">
                    <img class="position-absolute" src="assets/img/slider/cinema-slide.jpg" />
                    <div class="black-layer position-absolute top-0 end-0 h-100 w-100"></div>
                </div>
                <div
                    class="position-relative d-flex align-items-lg-end justify-content-center institute-pref flex-column inner">
                    <div class="blend-darken-bg position-absolute top-0 end-0 h-100 w-100"></div>
                    <div
                        class="pref position-relative text-white pe-lg-5 pe-3 ms-3 animate__animated animate__slideInRight wow">
                        <h2 class="heading-font d-inline-block pt-5">
                            <label class="d-block text-sm-start">1957</label>
                            المعهد العالي للسينما
                        </h2>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb animate__animated animate__fadeInDown">
                                <li class="breadcrumb-item mb-2 mb-md-0">
                                    <a class="text-white">أكادميه الفنون</a>
                                </li>
                                <li class="breadcrumb-item mb-2 mb-md-0">
                                    <a class="text-white">
                                        المعهد العالي للسينما
                                    </a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    الدراسات الحرة
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        <div class="container-fluid floated-contatiner-top position-relative">
            <div class="row">
                <div class="col pe-md-0">
                    <div class=" px-md-3  bg-white">
                        <section class="pt-4 mt-3 col-lg-10 mx-auto">
                            <h3
                                class="text-center heading-font heading-style mb-4 fw-normal animate__animated animate__fadeInUp animate__faster wow">
                                الدراسات الحرة
                            </h3>
                            <p class="fs-5 fw-medium animate__animated animate__fadeInUp animate__fast wow">
                                نظام تعليمي مسائي حر يقوم على نظام الدورات المتتالية، مدة الدراسة فيه نحو عامين أو 12
                                دورة بنظام دورة كل 6 أسابيع وهو لايمنح درجة علمية يمكن معادلتها من أي مكان وهى تمنح
                                شهادة بإتمام الدورات معتمدة من المعهد العالى للسينما
                            </p>
                        </section>
                        <section class="py-5 d-lg-flex">
                            <!--desktop side filter-->
                            <aside class="side-filter pt-4 d-none d-lg-block">
                                <div class="floating-label-select position-relative border mb-4">
                                    <select class="floating-select form-select border-0 fw-medium"
                                        onclick="this.setAttribute('value', this.value);" value="1">
                                        <option selected value="1">الأحدث</option>
                                        <option value="2">الأكثر رواجاً</option>
                                        <option value="3">السعر: من الأعلى إلى الأقل</option>
                                        <option value="4">السعر: من الأقل إلى الأعلى</option>
                                    </select>
                                    <label>ترتيب حسب</label>
                                </div>
                                <div class="accordion">
                                    <a class="btn bg-primary-light text-primary fw-medium mb-3 ">
                                        حذف الكل
                                    </a>
                                    <div class="accordion-item border-0 border-top">
                                        <h2 class="accordion-header" id="headingFilterDepartments">
                                            <button
                                                class="accordion-button bg-transparent fw-medium fs-4 text-black shadow-none px-0"
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFilterDepartments" aria-expanded="true"
                                                aria-controls="collapseFilterDepartments">
                                                الأقسام
                                            </button>
                                        </h2>
                                        <div id="collapseFilterDepartments" class="accordion-collapse collapse show"
                                            aria-labelledby="headingFilterDepartments">
                                            <div class="accordion-body px-0 py-0 text-secondary fw-medium">
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value="" id="check1"
                                                        checked>
                                                    <label class="form-check-label" for="check1">
                                                        السيناريو <span class="text-black">(10)</span>
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check2">
                                                    <label class="form-check-label" for="check2">
                                                        الاخراج <span class="text-black">(10)</span>
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check3">
                                                    <label class="form-check-label" for="check3">
                                                        المونتاج <span class="text-black">(10)</span>
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check4">
                                                    <label class="form-check-label" for="check4">
                                                        الانتاج <span class="text-black">(10)</span>
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check5">
                                                    <label class="form-check-label" for="check5">
                                                        هندسة الصوت <span class="text-black">(10)</span>
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check6">
                                                    <label class="form-check-label" for="check6">
                                                        هندسة المناظر <span class="text-black">(10)</span>
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check7">
                                                    <label class="form-check-label" for="check7">
                                                        الرسوم المتحركة <span class="text-black">(59)</span>
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check8">
                                                    <label class="form-check-label" for="check8">
                                                        التصوير <span class="text-black">(58)</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item border-0 border-top">
                                        <h2 class="accordion-header" id="headingFilterLevel">
                                            <button
                                                class="accordion-button bg-transparent fw-medium fs-4 text-black shadow-none px-0"
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFilterLevel" aria-expanded="false"
                                                aria-controls="collapseFilterLevel">
                                                المستوى
                                            </button>
                                        </h2>
                                        <div id="collapseFilterLevel" class="accordion-collapse collapse show"
                                            aria-labelledby="headingFilterLevel">
                                            <div class="accordion-body px-0 pb-0">
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check9">
                                                    <label class="form-check-label" for="check9">
                                                        مبتدئ <span class="text-black">(58)</span>
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check9">
                                                    <label class="form-check-label" for="check9">
                                                        جميع المستويات <span class="text-black">(58)</span>
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check9">
                                                    <label class="form-check-label" for="check9">
                                                        متوسط <span class="text-black">(58)</span>
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check9">
                                                    <label class="form-check-label" for="check9">
                                                        خبير <span class="text-black">(58)</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item border-0 border-top">
                                        <h2 class="accordion-header" id="headingFilterTimeFrame">
                                            <button
                                                class="accordion-button bg-transparent fw-medium fs-4 text-black shadow-none px-0"
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFilterTimeFrame" aria-expanded="false"
                                                aria-controls="collapseFilterTimeFrame">
                                                المدة الزمنية
                                            </button>
                                        </h2>
                                        <div id="collapseFilterTimeFrame" class="accordion-collapse collapse show"
                                            aria-labelledby="headingFilterTimeFrame">
                                            <div class="accordion-body px-0 pb-0">
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check9">
                                                    <label class="form-check-label" for="check9">
                                                        أقل من شهر <span class="text-black">(58)</span>
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check9">
                                                    <label class="form-check-label" for="check9">
                                                        1-3 شهور <span class="text-black">(58)</span>
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="check9">
                                                    <label class="form-check-label" for="check9">
                                                        أكثر من 3 شهور<span class="text-black">(58)</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                            <!--end desktop side filter-->
                            <!--mobile side filter-->
                            <div class="row d-lg-none">
                                <div class="col">
                                    <div class="floating-label-select position-relative border">
                                        <select class="floating-select form-select border-0 fw-medium"
                                            onclick="this.setAttribute('value', this.value);" value="1">
                                            <option selected value="1">الأحدث</option>
                                            <option value="2">الأكثر رواجاً</option>
                                            <option value="3">السعر: من الأعلى إلى الأقل</option>
                                            <option value="4">السعر: من الأقل إلى الأعلى</option>
                                        </select>
                                        <label>ترتيب حسب</label>
                                    </div>

                                </div>
                                <div class="col-auto ps-0 pe-md-0">
                                    <a class="btn border text-black d-flex align-items-center h-100"
                                        data-bs-toggle="offcanvas" href="#offcanvasSideFilter" role="button"
                                        aria-controls="offcanvasSideFilter">
                                        <i class="icon icon-filter default"></i>
                                    </a>
                                </div>
                            </div>
                            <!--end mobile side filter-->
                            <!--feed listing-->
                            <div class="feed-listing plus-filter">
                                <div class="pb-4">
                                    <a class="row py-4 border-bottom card-hover text-black border-0 feed-item">
                                        <div class="col-sm-auto">
                                            <div class="list-img position-relative overflow-hidden">
                                                <img class="fit-img position-absolute" src="assets/img/course-1.jpg">
                                            </div>
                                        </div>
                                        <div class="col fw-medium">
                                            <h5 class="fs-5 mb-2 lh-base fw-bold" routerLink="/course_details">
                                                تطور فكر فلسفي
                                            </h5>
                                            <div class=" mb-2 d-flex align-items-center">
                                                <span class="">د/ إسم المحاضر</span>
                                                <label
                                                    class="badge bg-primary-light ms-2 py-1 px-2 lh-base text-black d-sm-inline-block d-none">
                                                    قسم السيناريو
                                                </label>
                                            </div>
                                            <p class="brife mb-2 opacity-75 pt-1 lh-base d-none d-sm-block">
                                                تنقسم دراسة تاريخ الفلسفة تقليدياً إلى ثلاث مراحل: العصور اليونانية
                                                القديمة، القرون الوسطى، والعصور الحديثة
                                            </p>
                                            <div class="text-black main-points">
                                                12 ساعة - 6 محاضرات - جميع المستويات
                                            </div>
                                            <label
                                                class="badge bg-primary-light mt-2 py-1 px-2 lh-base text-black d-sm-none d-inline-block">
                                                قسم السيناريو
                                            </label>
                                        </div>
                                        <div class="col-md-auto price">
                                            <span class="fw-bold fs-3">1450</span> <span>جم</span>
                                        </div>
                                    </a>
                                    <a class="row py-4 border-bottom card-hover text-black border-0 feed-item">
                                        <div class="col-sm-auto">
                                            <div class="list-img position-relative overflow-hidden">
                                                <img class="fit-img position-absolute" src="assets/img/course-2.jpg">
                                            </div>
                                        </div>
                                        <div class="col fw-medium">
                                            <h5 class="fs-5 mb-2 lh-base fw-bold">
                                                تاريخ وتذوق فنون
                                            </h5>
                                            <div class=" mb-2 d-flex align-items-center">
                                                <span class="">د/ إسم المحاضر</span>
                                                <label
                                                    class="badge bg-primary-light ms-2 py-1 px-2 lh-base text-black d-sm-inline-block d-none">
                                                    قسم السيناريو
                                                </label>
                                            </div>
                                            <p class="brife mb-2 opacity-75 pt-1 lh-base d-none d-sm-block">
                                                تنقسم دراسة تاريخ الفلسفة تقليدياً إلى ثلاث مراحل: العصور اليونانية
                                                القديمة، القرون الوسطى، والعصور الحديثة
                                            </p>
                                            <div class="text-black main-points">
                                                12 ساعة - 6 محاضرات - جميع المستويات
                                            </div>
                                            <label
                                                class="badge bg-primary-light mt-2 py-1 px-2 lh-base text-black d-sm-none d-inline-block">
                                                قسم السيناريو
                                            </label>
                                        </div>
                                        <div class="col-md-auto price">
                                            <span class="fw-bold fs-3">1450</span> <span>جم</span>
                                        </div>
                                    </a>
                                    <a class="row py-4 border-bottom card-hover text-black border-0 feed-item">
                                        <div class="col-sm-auto">
                                            <div class="list-img position-relative overflow-hidden">
                                                <img class="fit-img position-absolute" src="assets/img/course-3.jpg">
                                            </div>
                                        </div>
                                        <div class="col fw-medium">
                                            <h5 class="fs-5 mb-2 lh-base fw-bold">
                                                فنية تطور تاريخ
                                            </h5>
                                            <div class=" mb-2 d-flex align-items-center">
                                                <span class="">د/ إسم المحاضر</span>
                                                <label
                                                    class="badge bg-primary-light ms-2 py-1 px-2 lh-base text-black d-sm-inline-block d-none">
                                                    قسم السيناريو
                                                </label>
                                            </div>
                                            <p class="brife mb-2 opacity-75 pt-1 lh-base d-none d-sm-block">
                                                تنقسم دراسة تاريخ الفلسفة تقليدياً إلى ثلاث مراحل: العصور اليونانية
                                                القديمة، القرون الوسطى، والعصور الحديثة
                                            </p>
                                            <div class="text-black main-points">
                                                12 ساعة - 6 محاضرات - جميع المستويات
                                            </div>
                                            <label
                                                class="badge bg-primary-light mt-2 py-1 px-2 lh-base text-black d-sm-none d-inline-block">
                                                قسم السيناريو
                                            </label>
                                        </div>
                                        <div class="col-md-auto price">
                                            <span class="fw-bold fs-3">1450</span> <span>جم</span>
                                        </div>
                                    </a>
                                    <a class="row py-4 border-bottom card-hover text-black border-0 feed-item">
                                        <div class="col-sm-auto">
                                            <div class="list-img position-relative overflow-hidden">
                                                <img class="fit-img position-absolute" src="assets/img/course-4.jpg">
                                            </div>
                                        </div>
                                        <div class="col fw-medium">
                                            <h5 class="fs-5 mb-2 lh-base fw-bold">
                                                تطور فكر فلسفي
                                            </h5>
                                            <div class=" mb-2 d-flex align-items-center">
                                                <span class="">د/ إسم المحاضر</span>
                                                <label
                                                    class="badge bg-primary-light ms-2 py-1 px-2 lh-base text-black d-sm-inline-block d-none">
                                                    قسم السيناريو
                                                </label>
                                            </div>
                                            <p class="brife mb-2 opacity-75 pt-1 lh-base d-none d-sm-block">
                                                تنقسم دراسة تاريخ الفلسفة تقليدياً إلى ثلاث مراحل: العصور اليونانية
                                                القديمة، القرون الوسطى، والعصور الحديثة
                                            </p>
                                            <div class="text-black main-points">
                                                12 ساعة - 6 محاضرات - جميع المستويات
                                            </div>
                                            <label
                                                class="badge bg-primary-light mt-2 py-1 px-2 lh-base text-black d-sm-none d-inline-block">
                                                قسم السيناريو
                                            </label>
                                        </div>
                                        <div class="col-md-auto price">
                                            <span class="fw-bold fs-3">1450</span> <span>جم</span>
                                        </div>
                                    </a>
                                </div>
                                <div class="text-center pt-3">
                                    <!-- <div class="progress" style="height: 1px;">
                      <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div> -->
                                    <a
                                        class="btn btn-link fs-5 all d-inline-flex align-items-center px-3 py-2 fw-medium border border-primary text-start">
                                        المزيد من الدورات
                                        <i class="icon-arrow-sm"></i>
                                    </a>

                                </div>
                            </div>
                            <!--end feed listing-->
                        </section>
                        <section class="py-5 px-2">
                            <h2
                                class="heading-font mb-1 fw-normal text-center animate__animated animate__fadeInUp animate__faster wow">
                                الدراسات الحرة ليست المناسب لك؟
                            </h2>
                            <p
                                class="mb-4 fs-5 opacity-75 text-center fw-medium animate__animated animate__fadeInUp animate__fast wow">
                                برنامج الدراسات الحرة ليس مناسب لمهاراتك يمكنك اللإطلاع على البرامج الأخرى
                            </p>
                            <div class="row">
                                <div class="col-lg-10 mx-auto">
                                    <div class="row">
                                        <div
                                            class="col-md-6 mb-md-0 mb-5 animate__animated animate__fadeInUp animate__fast wow">
                                            <a
                                                class="position-relative hover-img overflow-hidden programs-link d-block">
                                                <img class="w-100" src="assets/img/ba.jpg">
                                                <div
                                                    class="position-absolute bottom-0 end-0 p-4 bg-white fs-4 text-black fw-medium d-flex align-items-center">
                                                    دراﺳﺎت اﻟﺒﻜﺎﻟﻮرﻳﻮس
                                                    <i class="icon-arrow-sm text-primary ms-1"></i>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-6 animate__animated animate__fadeInUp wow">
                                            <a
                                                class="position-relative hover-img overflow-hidden programs-link d-block">
                                                <img src="assets/img/master.jpg">
                                                <div
                                                    class="position-absolute top-0 start-0 p-4 bg-white fs-4 text-black fw-medium d-flex align-items-center">
                                                    اﻟﺪراﺳﺎت اﻟﻌﻠﻴﺎ
                                                    <i class="icon-arrow-sm text-primary ms-1"></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <section class="container-fluid pt-lg-5 pt-3 pb-lg-5 pb-3">
            <div class="row">
                <div
                    class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__faster wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            موقع الطالب
                            <p class="mb-0 text-gray mt-1">
                                تعرف على المواد و المهام و النتيجة
                            </p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
                <div
                    class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__fast wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            موقع هيئة التدريس
                            <p class="mb-0 text-gray mt-1">تابع متطلبات الطلبه</p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
                <div
                    class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp animate__fast wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            موقع الموظفين
                            <p class="mb-0 text-gray mt-1">تابع متطلبات الطلبه</p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
                <div class="col-lg-3 col-md-6 mb-lg-0 mb-3 pb-lg-0 pb-3 animate__animated animate__fadeInUp wow">
                    <a class="btn caption border d-flex align-items-center text-start p-3 fs-6 h-100">
                        <div class="fw-medium flex-fill p-1">
                            خدمات الخريجين
                            <p class="mb-0 text-gray mt-1">
                                تعرف على الخدمات الخاصه بالخرجين
                            </p>
                        </div>
                        <i class="icon-arrow-sm m-1 default d-block overflow-hidden"></i>
                    </a>
                </div>
            </div>
        </section>

    </main>
</div>